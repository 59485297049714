import { Button, Card, List, Stack, Text } from "@mantine/core";
import { TickSquare } from "iconsax-react";
import { useState } from "react";

import { PlatinumCardProps } from "../../types/pricing";
import { useStyles } from "./styles";

const PlatinumCard = ({ package_data }: PlatinumCardProps) => {
  const { classes } = useStyles();
  const { name, price, features, CTA, includingFrom } = package_data;

  const [selectedBillingTerm, setBillingTerm] = useState<
    "monthly" | "annually"
  >("annually");

  const handleRegisterClick = () => {
    const registerUrl = "https://auth.formslides.com/register";
    window.open(registerUrl, "_blank");
  };

  return (
    <Card padding="none" className={`${classes.pricing_card} ${classes.platinum_plus_card}`}>
      <Stack pt={24} px={24} pb={24} spacing={30}>
        <Stack>
          <Text className={classes.special} size="lg">{name}</Text>
          <Text weight="bold" color="black" size={"1.5rem"}>
            {typeof price === "string" && price}
          </Text>

        </Stack>
        <div className={classes.divider}></div>
        <List
          withPadding
          size="md"
          icon={<TickSquare  color="#697689" variant="Bulk" />}
          className={classes.features}
        >
          <Text mb="xl">
            {includingFrom}
          </Text>

          {features.map((feature) => (
            <List.Item mb="sm" 
            key={feature}>
              {feature}
            </List.Item>
          ))}
        </List>
        <Button
          className={classes.get_started_button}
          variant="default"
          my="xl"
          radius="xl"
          fullWidth
          onClick={handleRegisterClick}
          style={{
            marginTop: "10.75rem",
          }}
        >
          Get stated now
        </Button>
      </Stack>
    </Card>
  );
};

export default PlatinumCard;

import {
  Anchor,
  Burger,
  Button,
  Divider,
  Drawer,
  Group,
  Header,
  ScrollArea,
  rem,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React, { useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { ReactComponent as LogoSvg } from "@/assets/logo.svg";
import { useStyles } from "./styles";

type NavigationItem = {
  label: string;
  href: string;
};

const Navigations: NavigationItem[] = [
  { label: "Features", href: "/#features" },
  { label: "Pricing", href: "/#pricing" },
  { label: "FAQ", href: "/#faq" },
];

function Navbar() {
  const [cookies] = useCookies(["isLoggedIn"]);
  const isLoggedIn = cookies.isLoggedIn === "true";

  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const { classes, theme } = useStyles();

  const location = useLocation();

  React.useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const locationPathname = location.pathname;
  const previousPage = useRef(locationPathname);
  useEffect(() => {
    if (locationPathname !== previousPage.current) {
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        // for older browser
        window.scrollTo(0, 0);
      }
      previousPage.current = locationPathname;
    }
  }, [locationPathname]);

  return (
    <>
      <Header
        height={50}
        mb={50}
        px="md"
        bg="transparent"
        className={classes.header}
      >
        <Group position="apart" sx={{ height: "100%" }}>
          <Group
            sx={{
              height: "100%",
              "& svg": {
                witdh: 35,
                height: 35,
              },
            }}
          >
            <Anchor component={Link} to="/" className={classes.logoContainer}>
              <LogoSvg />
              <span className={classes.logoText}>
                Form Slides<span style={{ color: "#ffb238" }}></span>
              </span>
            </Anchor>

            <Group
              sx={{ height: "100%" }}
              spacing={0}
              className={classes.hiddenMobile}
            >
              {Navigations.map(({ label, href }) => (
                <Anchor
                  component={HashLink}
                  to={href}
                  underline={false}
                  className={classes.link}
                  key={label}
                >
                  {label}
                </Anchor>
              ))}
            </Group>
          </Group>

          <Group className={classes.hiddenMobile}>
            {isLoggedIn ? (
              <Button
                component={Link}
                to={import.meta.env.VITE_APP_URL}
                size="xs"
                radius="xl"
                style={{backgroundColor: "#ffb238"}}
              >
                Go to dashboard
              </Button>
            ) : (
              <>
                <Button
                  component={Link}
                  to={`${import.meta.env.VITE_AUTH_URL}/login`}
                  size="xs"
                  radius="xl"
                  variant="default"
                  w={100}
                >
                  Log in
                </Button>
                <Button
                className={classes.button}
                  component={Link}
                  to={`${import.meta.env.VITE_AUTH_URL}/register`}
                  size="xs"
                  radius="xl"
                  w={100}
                  
                >
                  Sign up
                </Button>
              </>
            )}
          </Group>
          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            className={classes.hiddenDesktop}
          />
        </Group>
      </Header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Navigation"
        className={classes.hiddenDesktop}
        zIndex={1000000}
      >
        <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
          <Divider
            my="sm"
            color={theme.colorScheme === "dark" ? "dark.5" : "gray.0"}
          />
          {Navigations.map(({ label, href }) => (
            <Anchor
              href={href}
              onClick={closeDrawer}
              underline={false}
              className={classes.link}
              key={label}
            >
              {label}
            </Anchor>
          ))}
          <Divider
            my="sm"
            color={theme.colorScheme === "dark" ? "dark.5" : "gray.0"}
          />
          <Group position="center" grow pb="xl" px="md">
            {isLoggedIn ? (
              <Button component={Link} to={import.meta.env.VITE_APP_URL}
              style={{backgroundColor: "#ffb238"}}
              >
                Go to dashboard
              </Button>
            ) : (
              <>
                <Button
                  component={Link}
                  to={`${import.meta.env.VITE_AUTH_URL}/login`}
                  variant="default"
                >
                  Log in
                </Button>
                <Button
                  component={Link}
                  to={`${import.meta.env.VITE_AUTH_URL}/register`}
                >
                  Sign up
                </Button>
              </>
            )}
          </Group>
        </ScrollArea>
      </Drawer>
    </>
  );
}

export default Navbar;

import { useState } from "react";
import { Badge, Button, Card, List, Stack, Tabs, Text } from "@mantine/core";
import { TickSquare } from "iconsax-react";

import { GoldCardProps } from "../../types/pricing";
import { useStyles } from "./styles";

const GoldCard = ({ package_data }: GoldCardProps) => {
  const { classes } = useStyles();
  const { name, price, features, CTA } = package_data;

  const [selectedBillingTerm, setBillingTerm] = useState<
    "monthly" | "annually"
  >("annually");

  const handleRegisterClick = () => {
    const registerUrl = "https://auth.formslides.com/register";
    window.open(registerUrl, "_blank");
  };

  return (
    <Card
      padding="none"
      className={`${classes.pricing_card} ${classes.plus_card}`}
    >
      <Stack pt={24} px={24} pb={24} spacing={30}>
        <Stack>
          <Text size="lg">{name}</Text>
          <Text
            weight="bold"
            color="black"
            size={"1.5rem"}
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            {price[selectedBillingTerm]?.price}{" "}
            {price[selectedBillingTerm]?.discount && (
              <Badge>{price[selectedBillingTerm]?.discount}</Badge>
            )}
          </Text>
        </Stack>

        <div className={classes.divider}>
          <Tabs
            defaultValue="monthly"
            value={selectedBillingTerm}
            onTabChange={setBillingTerm as never}
            radius="xl"
            variant="pills"
          >
            <Tabs.List
              mx="xs"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "180px",
              }}
            >
              <Tabs.Tab value="monthly">Monthly</Tabs.Tab>
              <Tabs.Tab value="annually">Annually</Tabs.Tab>
            </Tabs.List>
          </Tabs>
        </div>

        <List
          withPadding
          size="sm"
          icon={<TickSquare color="#697689" variant="Bulk" />}
          className={classes.features}
        >
          <Text mb="xl">Everything in Free, plus…</Text>
          {features.map((feature) => (
            <List.Item mb="sm" key={feature}>
              {feature}
            </List.Item>
          ))}
        </List>
        <Button
          className={classes.get_started_button}
          onClick={handleRegisterClick}
          my="xs"
          radius="xl"
          variant="default"
          fullWidth
          style={{
            marginTop: "11.98rem",
          }}
        >
          {CTA}
        </Button>
      </Stack>
    </Card>
  );
};

export default GoldCard;

import CookieConsent from "react-cookie-consent";

import AppRouter from "./rotuer";
import { ThemeProvider } from "./theme/theme-provider";

function App() {
  return (
    <>
      <ThemeProvider>
        <AppRouter />

        <CookieConsent
          location="bottom"
          buttonText="I understand"
          style={{ background: "#2B373B", justifyContent: "center" }}
          buttonStyle={{
            color: "#fff",
            fontSize: "13px",
            background: "#1971c2",
            marginTop: "0px",
            border: "none",
            borderRadius: "4px",
            padding: "8px 12px",
          }}
          contentStyle={{ flex: "0 0 100%", textAlign: "center" }}
          expires={150}
        >
          This website uses cookies to authenticate the user and enhance the
          user experience. By using the website, you agree to our use of
          cookies.
        </CookieConsent>
      </ThemeProvider>
    </>
  );
}

export default App;

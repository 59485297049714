import { Flex, Title } from "@mantine/core";

import { PricingPackages } from "../../types/pricing";
import FreeCard from "./basic";
import GoldCard from "./gold";
import PlatinumCard from "./platinum";
import { useStyles } from "./styles";

const PRICING_PACKAGES: PricingPackages = {
  free: {
    name: "Free",
    price: "0$",
    features: [
      "Unlimited responses",
      "Unlimited forms",
      "Form layout customization (images, colors, themes, etc)",
      "Embed forms to your website",
      "Get email notifications",
      "Customize the welcome page",
      "Customize the confirmation page",
      "Customize the thank you page",
      "Max file upload - 50MB per file",
      "Create rules based on users' responses",
      "Create a quiz and score",
      "Integrate with other apps (coming soon)",
    ],
    CTA: "Get started now",
  },
  gold: {
    name: "Gold",
    price: {
      monthly: {
        price: "20$ per month",
        discount: "",
      },
      annually: {
        discount: "-20%",
        price: "16$ per month",
      },
    },
    includingFrom: "Everything from Free, plus",
    features: [
      "Max file upload - 100MB per file (Contact us for more)",
      "Remove branding and add your own logo",
      "Schedule form availability (enable and disable on specific dates)",
      "Disable the form after a specific number of responses",
      "Your own subdomain (coming soon)",
      "Use AI to create forms (coming soon)",
      "Invite team members (coming soon)",
    ],
    CTA: "Get started now",
  },
  platinum: {
    name: "Platinum - One time payment",
    price: "350$",
    includingFrom: "Everything from Free and Gold, plus",
    features: [
      "Pay once, use forever",
      "All features including from Free and Gold",
      "At least 5 years of guaranteed availability",
      "Get a full refund if the service is discontinued within 5 years",
      "Priority support and feature requests",
      "Only for individuals and small businesses (for enterprises, contact us for custom plans)",
    ],
    CTA: "Get started now",
  },
};

const Pricing = () => {
  const { classes } = useStyles();

  return (
    <section id="pricing">
      <div className={classes.title}>
        <Title order={2} size="h1" align="center" mb="lg">
          Pricing
        </Title>
      </div>

      <Flex
        justify="space-between"
        direction={{
          base: "column",
          md: "row",
        }}
        gap="sm"
        my="xl"
      >
        <FreeCard package_data={PRICING_PACKAGES.free} />
        <GoldCard package_data={PRICING_PACKAGES.gold} />
        <PlatinumCard package_data={PRICING_PACKAGES.platinum} />
      </Flex>
    </section>
  );
};

export default Pricing;

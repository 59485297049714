import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  header: {
    borderBottomColor: theme.colors.gray[9],
    position: "sticky",
    top: 0,
    left: 0,
    right: 0,
    backdropFilter: "blur(20px)",
  },
  link: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.md,

    [theme.fn.smallerThan("sm")]: {
      height: rem(42),
      display: "flex",
      alignItems: "center",
      width: "100%",
    },

    ...theme.fn.hover({
      // backgroundColor: theme.colors.gray[2],
      // underline with the line on hover
      textDecoration: "underline",
    }),
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      textDecoration: "none",
    },
  },
  button: {
    color: "#fff",
    backgroundColor: "#ffb238",
    "&:hover": {
      backgroundColor: "#e3b058",
    }
  },
  logoText: {
    marginLeft: theme.spacing.xs,
    color: "black",
    fontWeight: 600,
  },

  hiddenMobile: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
}));

import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

const Unsubsctibe = () => {
  // take your email from the page url and make a call to the backend to unsubscribe
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState("");

  useMemo(() => {
    const email = searchParams.get("email");
    if (!email) {
      return;
    }
    const url = "https://api.formslides.com/v1/user/marketing/unsubscribe";
    fetch(url, {
      method: "POST",
      body: JSON.stringify({ email }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const parsedRes = await res.json();
        setMessage(parsedRes.message);
      })
      .catch((err) => {
        setMessage(
          `Something went wrong - ${err.message}. Please contact us to solve the issue.`
        );
      });
  }, [searchParams]);

  return (
    <div>
      <h1>Unsubscribe</h1>
      <h3>{message}</h3>
    </div>
  );
};

export default Unsubsctibe;

import { Button, Card, List, Stack, Text } from "@mantine/core";
import { TickSquare } from "iconsax-react";

import { FreeCardProps } from "../../types/pricing";
import { useStyles } from "./styles";

const FreeCard = ({ package_data }: FreeCardProps) => {
  const { classes } = useStyles();
  const { price, name, features, CTA } = package_data;

  const handleRegisterClick = () => {
    const registerUrl = "https://auth.formslides.com/register";
    window.open(registerUrl, "_blank");
  };

  return (
    <Card
      padding="none"
      className={`${classes.pricing_card} ${classes.plus_card}`}
    >
      <Stack pt={24} px={24} pb={24} spacing={30}>
        <Stack>
          <Text size="lg">{name}</Text>
          <Text weight="bold" color="black" size={"1.5rem"}>
            {typeof price === "string" && price}
          </Text>
        </Stack>
        <div className={classes.divider}></div>
        <List
          withPadding
          size="sm"
          icon={<TickSquare  color="#697689" variant="Bulk" />}
          className={classes.features}
        >
      <Text mb="xl"> Free forever  </Text>
          {features.map((feature) => (
            <List.Item mb="sm" key={feature}>
              {feature}
            </List.Item> 
          ))}
        </List>
        <Button
        className={classes.get_started_button}
          variant="default"
          my="xs"
          radius="xl"
          fullWidth
          onClick={handleRegisterClick}
        >
          {CTA}
        </Button>
      </Stack>
    </Card>
  );
};

export default FreeCard;

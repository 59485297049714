import { Text } from "@mantine/core";

const TermsOfService = () => {
  return (
    <Text align="left">
      <h1>Terms of Service</h1>
      <p>
        By accessing or using our website (
        <a href="https://formslides.com">https://formslides.com</a>) you agree to be
        bound by these Terms of Service and all applicable laws and regulations.
        If you do not agree to these Terms, you are prohibited from using or
        accessing this website or any other services Form Slides provides.
      </p>
      <h2>Privacy</h2>
      <p>
        Please read our privacy policy (<a href="privacy-policy">link</a>) for
        more information.
      </p>
      <h2>Payments</h2>
      <p>
        Form Slides does not process any order payments through the website. All
        payments are processed securely through Stripe, a third-party
        online payment provider. Read more about Stripe’s privacy (
        <a href="https://www.stripe.com/privacy">
          https://www.stripe.com/privacy
        </a>
        ) and term (
        <a href="https://www.stripe.com/terms">
          https://www.stripe.com/terms
        </a>
        ).
      </p>
      <h2>Intellectual Property</h2>
      <p>
        The materials on this website, including intellectual property, are
        owned by or licensed to Form Slides and safeguarded by copyright and
        trademark laws. Users are granted permission to download one copy of the
        materials.
      </p>
    </Text>
  );
};

export default TermsOfService;

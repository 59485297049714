import { Container } from "@mantine/core";

import FAQ from '@/components/faq';
import Features from '@/components/features';
import Hero from "@/components/hero";
import Pricing from '@/components/pricing';

const Home = () => {
  return (
    <Container size="lg">
      <Hero />
      {/* <HowItWorks /> */}
      <Features />
      <Pricing />
      <FAQ />
    </Container>
  );
};

export default Home;

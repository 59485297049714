/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from "react";
import { Carousel } from "@mantine/carousel";
import { Image, Title } from "@mantine/core";
import Autoplay from "embla-carousel-autoplay";

const images: any[] = [
  {
    url: "/rules.png",
    title: "Create advanced rules and conditions",
  },
  {
    url: "/quiz.png",
    title: "Create quizzes and score",
  },
  {
    url: "/form-availability.png",
    title: "Advanced form availability options",
  },
  {
    url: "/form-parts.png",
    title: "Customize form pages",
  },
];

// const allFeatures = [
//   "Unlimited responses",
//   "Unlimited forms",
//   "Form layouts customization (images, colors, etc)",
//   "Embed forms in your website",
//   "Get email notifications",
//   "Customize welcome page",
//   "Customize confirmation page",
//   "Customize thank you page",
//   "Max file upload - 50mb per file",
//   "Create rules based on users responses",
//   "Create quiz and score",
//   "Integrate with other apps (coming soon)",
//   "Max file upload - 100mb per file (Contact us for more)",
//   "Remove branding and add your own logo",
//   "Schedule form availability (enable and disable on specific dates)",
//   "Disable form after specific number of responses",
//   "Your own subdomain (coming soon)",
//   "Use AI to create forms (coming soon)",
//   "Invite team members (coming soon)",
//   "Pay once, use forever",
//   "All features including from Free and Gold",
//   "At least 5 years of guaranteed availability",
//   "Get full refund if the service is discontinued within 5 years",
//   "Priority support and feature requests",
//   "Only for individuals and small businesses (for enterprises, contact us for custom plans)",
// ]

const Features = () => {
  const autoplay = useRef(Autoplay({ delay: 5000 }));

  const slides = images.map((image) => (
    <Carousel.Slide key={image.url}>
      <Image src={image.url} />
      <Title
        style={{
          textAlign: "center",
          fontSize: "17px",
          paddingTop: "5px",
        }}
      >
        {image.title}
      </Title>
    </Carousel.Slide>
  ));

  return (
    <section id="features">
      <h2 className="feature-section-title">
        {" "}
        <span className="introduction-highlight-4"></span> Use cases{" "}
      </h2>

      <div className="features-container">
        <div className="feature-container">
          <div className="feature-text-box">
            <h3 className="feature-title">Unlimited forms and responses</h3>

            <p className="feature-description">
              FormSlides.com offers unlimited forms and responses. You can
              create as many forms as you want and receive unlimited responses.
              All forms are mobile-friendly and can be embedded on your website.
            </p>
          </div>
          <div className="feature-video">
            <video
              className="video-hover"
              autoPlay
              playsInline
              loop
              muted
              preload="metadata"
            >
              <source src="/hero-1.mp4" type="video/mp4" />
            </video>
          </div>
        </div>

        <div className="feature-container-reverse">
          <div className="feature-video">
            <video
              className="video-hover"
              autoPlay
              playsInline
              loop
              muted
              preload="metadata"
            >
              <source src="/hero-2.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="feature-text-box">
            <h3 className="feature-title">Customize form pages</h3>

            <p className="feature-description">
              Each form is fully customizable. Customize welcome, confirmation,
              and thank you pages. Add layouts, themes, images and change the
              colors
            </p>
          </div>
        </div>

        <div className="feature-container">
          <div className="feature-text-box">
            <h3 className="feature-title">Form Slides </h3>
            <p className="feature-description">
              Form slides are also fully customizable and unique. From simple
              text to complex slides, you can create anything.
            </p>
          </div>
          <div className="feature-video">
            <video
              className="video-hover"
              autoPlay
              playsInline
              loop
              muted
              preload="metadata"
            >
              <source src="/hero-3.mp4" type="video/mp4" />
            </video>
          </div>
        </div>

        <div className="feature-container-reverse">
          <div className="feature-video">
            <video
              className="video-hover"
              autoPlay
              playsInline
              loop
              muted
              preload="metadata"
            >
              <source src="/hero-4.mp4" type="video/mp4" />
            </video>
          </div>
          <div className="feature-text-box">
            <h3 className="feature-title">Rules and scoring</h3>

            <p className="feature-description">
              FormSlides.com allows you to create advanced form rules based on
              user responses. You can also create quizzes and score them.
            </p>
          </div>
        </div>

        <div className="feature-container">
          <div className="feature-text-box">
            <h3 className="feature-title">Form Availability </h3>
            <p className="feature-description">
              Schedule form availability and disable forms after a specific
              number of responses. You can also disable forms on specific dates.
            </p>
          </div>
          <div className="feature-video">
            <video
              className="video-hover"
              autoPlay
              playsInline
              loop
              muted
              preload="metadata"
            >
              <source src="/hero-5.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: "2rem",
        }}
        className="files-carousel"
      >
        <h2
          style={{
            textAlign: "center",
          }}
        >
          More use cases
        </h2>
        <Carousel
          slideSize="70%"
          height="100%"
          slideGap="md"
          controlSize={24}
          loop
          dragFree
          plugins={[autoplay.current]}
          onMouseEnter={autoplay.current.stop}
          onMouseLeave={autoplay.current.reset}
          breakpoints={[
            { maxWidth: "md", slideSize: "50%" },
            { maxWidth: "sm", slideSize: "100%", slideGap: 0 },
          ]}
          style={{
            background: "#a4a2a8",
            paddingTop: "20px",
            paddingBottom: "10px",
          }}
        >
          {slides}
        </Carousel>
      </div>

      <div
        className="senja-embed hero-testimonial-full"
        data-id="be1716e5-5bf3-40d9-b2c8-5a01cf2c1cd6"
        data-lazyload="false"
      ></div>
    </section>
  );
};

export default Features;

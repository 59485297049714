import { Accordion, Container, createStyles, rem, Title } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  wrapper: {
    marginTop: `calc(${theme.spacing.xl} * 2)`,
    marginBottom: `calc(${theme.spacing.xl} * 2)`,
  },

  title: {
    marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
  },

  item: {
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.lg,
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[5]
    }`,
  },
}));

export default function FAQ() {
  const { classes } = useStyles();
  return (
    <section id="faq">
      <Container size="sm" className={classes.wrapper}>
        <Title align="center" className={classes.title}>
          Frequently Asked Questions
        </Title>

        <Accordion variant="separated">
          <Accordion.Item className={classes.item} value="one-time-payment">
            <Accordion.Control>
              How does one time payment work?
            </Accordion.Control>
            <Accordion.Panel>
              You can pay once and use it forever. You will get all the features
              and updates for a lifetime. You can use it for unlimited forms and
              responses. You will also get priority support. The availability of
              FormSlides.com is guaranteed for at least 5 years. You will get a
              full refund if we discontinue the service within 5 years.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item className={classes.item} value="is-it-free">
            <Accordion.Control>Is it free?</Accordion.Control>
            <Accordion.Panel>
              Yes, you can start free with unlimited forms and responses. While
              other form builders have very limited free plans, we offer a
              generous free plan. You can use it as long as you want.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item className={classes.item} value="how-does-it-work">
            <Accordion.Control>How does it work?</Accordion.Control>
            <Accordion.Panel>
              It's simple to get started. You need to sign up and create a form.
              Customize the form as you like and share it with your audience.
              You can embed the form on your website or share the link directly.
              When someone submits the form, you will get an email notification.
              You can also view the responses in the dashboard.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item className={classes.item} value="supported-files">
            <Accordion.Control>
              {" "}
              What file formats are supported to ask the users to upload?
            </Accordion.Control>
            <Accordion.Panel>
              Images: jpg, jpeg, png.
              <br />
              Document: pdf, txt, doc, docx, ppt, pptx, xls, xlsx, csv
              <br />
              Other: zip, rar
              <br />
              If you want to use other formats, please let us know.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item className={classes.item} value="privacy">
            <Accordion.Control>Where is the data stored?</Accordion.Control>
            <Accordion.Panel>
              We take your privacy seriously. This product is built on top of
              the <a href="https://scaletozeroaws.com">Scale to Zero AWS kit</a>
              , which follows the best practices of AWS. Every file is securely
              stored in AWS using AWS S3 service. It uses server-side SSE-S3
              encryption. With the SSE-S3 encryption, Amazon S3 uses one of the
              strongest block ciphers—256-bit Advanced Encryption Standard
              (AES-256) to encrypt each object uploaded to the bucket. Your
              files and data are safe with us.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item className={classes.item} value="refund">
            <Accordion.Control>
              Can I get a refund if I don't like it?
            </Accordion.Control>
            <Accordion.Panel>
              Unfortunately, we don't offer refunds. We offer a free plan, so
              you can try it before you buy it.
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Container>
    </section>
  );
}

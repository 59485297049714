import { Suspense } from "react";
import { LoadingOverlay } from "@mantine/core";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import MainLayout from "./layouts/main-layout";
import Home from "./pages/home";
import PrivacyPolicy from "./pages/privacy-policy";
import Roadmap from "./pages/roadmap";
import TermsOfService from "./pages/terms-of-service";
import Unsubscribe from "./pages/unsubscribe";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/roadmap",
        element: <Roadmap />,
      },
      {
        path: "/terms-of-service",
        element: <TermsOfService />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/unsubscribe",
        element: <Unsubscribe />,
      },
      {
        path: "*",
        element: <Navigate to="/" />,
      },
    ],
  },
]);

export function AppRouter() {
  return (
    <Suspense fallback={<LoadingOverlay visible={true} overlayBlur={2} />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default AppRouter;

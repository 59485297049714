import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  title: {
    marginBottom: "4rem",
  },
  pricing_card: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: rem(32),
    textAlign: "center",
    padding: "1px",
    width: "100%",
    zIndex: 1,
    borderRadius: rem(16),

    ["&::before"]: {
      content: '" "',
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      zIndex: -1,
      padding: 1,
      borderRadius: rem(16),
  }, 
  },
  plus_card: {
    background: "#fff",
    border: "1px solid #438dcc",
  },
  platinum_plus_card: {
    border: "2px solid #ffb238",
    // add special background color for platinum
    background: theme.colors.orange[0],
  },

  divider: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 34,
    ["&::before"]: {
      content: '" "',
      display: "block",
      height: 1,
      width: "100%",
      backgroundColor: theme.colors.dark[5],
    },
    ["&::after"]: {
      content: '" "',
      display: "block",
      height: 1,
      width: "100%",
      backgroundColor: theme.colors.dark[5],
    },
  },
  // platinum special name for eye catching 
  special: {
    // color: theme.colors.blue[6],
    fontWeight: 700,
    textDecoration: "underline",
    // textDecorationColor: theme.colors.blue[6],


  },

  features: {
    textAlign: "left",
  },
  get_started_button: {
    height: "50px",
    backgroundColor: "#ffb238",
    color: "black",
    "&:hover": {
      backgroundColor: "#e3b058",
    },
  }
}));

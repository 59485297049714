/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from "react";

import "./landing.css";

const Hero = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8, // Adjust this threshold as needed
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    const video = videoRef.current;

    if (video) {
      observer.observe(video);
    }

    return () => {
      if (video) {
        observer.unobserve(video);
      }
    };
  }, []);

  const handleIntersection = (entries: any) => {
    entries.forEach((entry: any) => {
      if (entry.isIntersecting) {
        const video = videoRef.current as any;
        if (video.paused) {
          video.play();
        }
      } else {
        const video = videoRef.current as any;
        if (!video.paused) {
          video.pause();
        }
      }
    });
  };

  const handleGetStarted = () => {
    // go to  "auth.formslides.com/login";

    window.location.href = "https://auth.formslides.com/login";
  };

  return (
    <section id="hero">
      <div className="container">
        <div className="hero">
          <h1 className="title">Unlimited forms and responses</h1>
          <h2 className="description ">
            Create and share forms and surveys with reasonable pricing and
            advanced features. Customize your forms and set rules based on user
            responses.
          </h2>
          {/* <div
            className="senja-embed hero-testimonial"
            data-id="07c553ed-9ba0-4703-92a6-71867c7a61a0"
            data-lazyload="false"
          ></div> */}

          {/* // add button */}

          <div className="hero-buttons">
            <div className="hero-button">
              <button
                className="btn-primary start-btn"
                onClick={handleGetStarted}
              >
                Create free unlimited forms now
              </button>

              {/* * add to checkmark
              1. No credit card required
              2. Unlimited forms and responses
               */}

              <div className="hero-lists">
                <div className="hero-list">
                  <span className="hero-list-checkmark">&#10004;&#65039;</span>{" "}
                  No credit card required
                </div>

                <div className="hero-list">
                  <span className="hero-list-checkmark">&#10004;&#65039;</span>{" "}
                  Unlimited forms and responses
                </div>
              </div>
            </div>

            <span className="sample-form-link-box">
              or &nbsp;
              <a
                className="sample-form-link"
                href="https://go.formslides.com/2dm5Z79PugDfI8Au4LdL1Tx6yXM"
                target="_blank"
                rel="noreferrer"
              >
                checkout our sample form{" "}
              </a>
            </span>
          </div>

          <div className="hero-video">
            <video
              ref={videoRef}
              style={{ width: "100%" }}
              playsInline
              loop
              muted
              preload="metadata"
            >
              <source src="/hero-video.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;

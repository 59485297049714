import { Text } from "@mantine/core";

const PrivacyPolicy = () => {
  return (
    <Text align="left">
      <h1>Privacy Page</h1>
      <p>
        <strong>Last updated: 11 March 2024</strong>
      </p>
      <p>
        This Privacy Policy applies to all users and visitors to the site. We
        are committed to protecting your privacy and handling your personal
        information responsibly. Your privacy is important to us. Form Slide's
        policy is to respect your privacy and{" "}
        <strong>comply with any applicable law and regulation</strong> regarding
        any information we may collect from you across our website and other
        sites we own and operate.
      </p>
      <p>
        We only ask for personal information when we truly need it.{" "}
        <strong>
          We keep your personal information only for as long as we need to.
        </strong>{" "}
        We don't share any personally identifying information publicly or with
        third parties.
      </p>
      <p>
        We fulfill the roles of a data controller and a data processor regarding
        the personal data handled through Form Slides and its associated
        services by relevant data protection laws, including the EU General Data
        Protection Regulation (GDPR).
      </p>
      <p>
        Please note that our website may include links to external sites that
        are not under our operation. We do not have control over the content and
        practices of these sites and, therefore, cannot assume responsibility or
        liability for their privacy policies. They might use cookies, collect
        data, and use the data.
      </p>
      <p>
        By continuing to use our website, you will be considered as agreeing to
        our privacy and personal information policies. If you have any inquiries
        regarding how we manage user data and personal information, please don't
        hesitate to contact us.
      </p>

      <h2> Usage of Google User Data </h2>
      <p>
        FormSlides.com uses Google as a sign-in provider. When you sign in using Google, we collect and process the following information from your Google account:

        <ul>
          <li>Google email address</li>
          <li>Google full name (if available)</li>
        </ul>

        <p>
        We only use this information for the following purposes:

        <ul>
          <li>User authentication</li>
          <li>Sending emails</li>
        </ul>

        <p>We do not share this information with any third parties, and we do not use it for any purposes other than those described above. 
        We do not store your Google password. We will never post anything to Google without your permission.
        </p>

        </p>
      </p>

      <p>
        <a href="mailto:contact@formslides.com">contact@formslides.com</a>
      </p>
      <p>This policy is effective as of 3 March 2024.</p>
    </Text>
  );
};

export default PrivacyPolicy;
